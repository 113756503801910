<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Modules"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onManage(v)"
              @on-update-cancel="(v) => loadModules()"
            >
              <template #columns>
                <DxColumn data-field="name" caption="Module" />
                <DxColumn data-field="description" caption="Description" />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="onShowModal"
      centered
      @hide="onCloseModal()"
      size="lg"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-group label="Name" label-for="v-name">
            <b-form-input v-model="formData.name" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Description" label-for="v-description">
            <b-form-textarea v-model="formData.description" />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onCloseModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import moduleApi from '@api/modules';
import { queryParameters } from '@/schema';

export default {
  name: 'ModulesPage',
  components: {},
  data: () => ({
    onShowModal: false,
    dataSource: [],
    formData: {
      id: 0,
    },
  }),
  mounted() {
    this.loadModules();
  },
  methods: {
    loadModules() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      moduleApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const onSuccess = () => {
        this.loadModules();
        this.onCloseModal();
      };

      if (!this.formData.id) {
        moduleApi
          .add(this.formData)
          .then(({ data }) => {
            onSuccess();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });

        return;
      }

      moduleApi
        .update(this.formData)
        .then(({ data }) => {
          onSuccess();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onManage(event) {
      this.onShowModal = true;

      if (event.name === 'edit') {
        this.formData = event.data;
      }
    },
    onDelete(event) {
      moduleApi
        .delete(event)
        .then(({ data }) => {
          this.loadModules();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onCloseModal() {
      this.onShowModal = false;
      this.formData = {
        id: 0,
      };
    },
  },
};
</script>

<style></style>
